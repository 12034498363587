import { ImageFormat, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button, { ButtonProps } from "../Button/Button"
import "./MainSection.scss"
import companies from "../../svg/companies_docs.svg"
import companiesMobile from "../../svg/companies_docs_mobile.svg"

interface MainSectionProps {
  title: string
  titleHighlight?: string
  caption?: string
  subtitle?: string
  imageLink?: string
  button?: ButtonProps
  showReferences?: boolean
  text?: string
  style?: React.CSSProperties
  children?:
    | React.ReactChild[]
    | React.ReactChild
    | React.ReactChildren[]
    | React.ReactChildren
  className?: string
  showPattern?: boolean
  divided?: boolean
}

const MainSection = ({
  title,
  titleHighlight,
  subtitle,
  caption,
  button,
  showReferences = false,
  text,
  style,
  children,
  className,
  showPattern,
  divided,
  imageLink,
  showReferencesDocs = false,
}: MainSectionProps) => {
  return (
    <div
      className={`MainSection ${className ?? ""} ${
        showPattern ? "pattern" : ""
      }`}
      style={style}
    >

      <div className="MainSection__wrapper w-wrap">
      
        {divided && (
          <div className="w1-2 MainSection__image">
              <img src={imageLink} alt="" />
          </div>   
        )}

        <div className={`${divided ? "w1-2" : "w1-1"}`}>
      
        {caption && (
          <div className="MainSection__caption">{caption}</div>
        )}
        {subtitle && (
          <div className="MainSection__subtitle">{subtitle.toUpperCase()}</div>
        )}
        <h1 className="MainSection__title">{title}<span>{titleHighlight}</span></h1>
        {text && <div className="MainSection__text">{text}</div>}{" "}
        {button && (
          <div className="MainSection__button">
            <Button {...button} />
          </div>
        )}
        <div className="MainSection__children">{children}</div>
        </div>
      </div>

      {showReferences && (
        <div className="MainSection__references-wrapper">
          <div className="MainSection__references">
            <p>TRUSTED BY</p>
            <div className="MainSection__references--divider"></div>
            <img
              src={
                typeof window !== "undefined" && window.innerWidth < 768
                  ? companiesMobile
                  : companies
              }
              alt="Companies who trust us"
              className="MainSection__trust-bar"
            />
          </div>
        </div>
      )}

      {showReferencesDocs && (
        <div className="MainSection__references">
          <div>TRUSTED BY</div>
          <div className="MainSection__references--divider"></div>
          <img
            src={
              typeof window !== "undefined" && window.innerWidth < 768
                ? companiesDocsMobile
                : companiesDocs
            }
            alt="Companies who trust us"
            className="MainSection__trust-bar"
          />
        </div>
      )}
    </div>
  )
}

export default MainSection
