import { Link } from "gatsby"
import React from "react"
import Button from "../../components/Button/Button"
import Columns from "../../components/Columns/Columns"
import Layout from "../../components/Layout/Layout"
import MainSection from "../../components/MainSection/MainSection"
import Section from "../../components/Section/Section"
import Seo from "../../components/Seo/Seo"
import Card from "../../components/Card/Card"

import QuoteSection from "../../components/QuoteSection/QuoteSection"
import "../../styles/caseStudies.scss"

import stripe_1 from "../../svg/stripe_1.svg"
import stripe_2 from "../../svg/stripe_2.svg"
import stripe_3 from "../../svg/stripe_3.svg"

import HabitoTheme from "../../images/habito_hero.jpg"

import TextWithIcon from "../../components/TextWithIcon/TextWithIcon"

const Habito = () => {
  return (
    <Layout>
      <Seo
        title="Habito Case Study"
        description="How Habito brought digital mortgage fraud to light."
      />

      <MainSection
        className="aml__main"
      >

        <div className="w-wrap">

          <div className="w1-2">
            <img alt="Detect fraud" src={HabitoTheme} />
          </div>

          <div className="w1-2">
            <div class="MainSection__caption">Case Study</div>
            <h1 className="MainSection__title">
              <span className="highlighted">How Habito brought</span> digital mortgage fraud <span className="highlighted">to light</span>
            </h1>
          </div>

        </div>

      </MainSection>

      <Section id="aml-challenges" sectionClass="caseStudies__description">
        <div className="w-wrap">
          <div className="w2-3">
            <p className="justified">
              <strong>Habito is an online mortgage broker and lender,</strong> whose mission is to make it easier
              to get a mortgage and buy a home. It regularly tops lists of best mortgage brokers
              in the UK thanks to its network of over 90 lending partners, through which it has
              delivered over $9 billion worth of loans. With its 2020 closure of a $42.6 million
              series C investment round, it is even expanding its services into its own line of
              mortgage offerings.
            </p>
            <p className="justified">
              Given this spectacular growth,
              <strong> keeping tight controls to prevent fraud from reaching partners without adding undue friction to customer journeys </strong>
              is a key objective of the company’s onboarding and risk mitigation strategies.
            </p>
          </div>
          <div className="w1-3">
            <div className="caseTestimonial">
              <h3 class="caseTestimonial__text">
                Resistant AI has helped us to drastically reduce both the time it takes to catch fraud, and the amount of fraud that makes it past us to lenders.
              </h3>
              <div className="caseTestimonial__bottom">
                <p><strong>Matthew Willis</strong></p>
                <p>Habito Financial Crime Investigator</p>
              </div>
            </div>
          </div>
      </div>

    </Section>

    <Section sectionClass="gray">

      <h2 className="mt-1">Habito’s <span className="highlighted">Fraud Challenge</span></h2>

      <div className="w-wrap m-reversed">

        <div className="w1-3">
          <div className="caseTestimonial caseTestimonialDark">
            <h3 class="caseTestimonial__text">
            Broadens your horizons to the risks that exist with digital documentation. Before, we were stumbling in the dark.
            </h3>
            <div className="caseTestimonial__bottom">
              <p><strong>Matthew Willis</strong></p>
              <p>Habito Financial Crime Investigator</p>
            </div>
          </div>
        </div>

        <div className="w2-3">
          <p className="justified">
            The usual escalation between fraudsters and investigators started to break down when it came to digital
            documents: forgeries on digital account statements and employment records were becoming forensic and
            invisible to the human eye.
          </p>
          <p className="justified">
            As a result, first-line risk teams were struggling to assess the authenticity of documents, and escalated
            cases were taking longer for financial crime investigators to resolve. To confirm the authenticity of documents,
            the teams were increasingly relying on outreach to the institutions that issued the documents, which slowed
            down decisions — if they got a response at all.
          </p>
          <p className="justified">
            This new situation threatened to bottleneck growth in an otherwise clockwork operation that prides itself
            on consistent iteration and execution. To address this rising challenge, Habito turned to Resistant AI’s Document Forensics.
          </p>
        </div>

      </div>

    </Section>

    <Section>
      <h2 className="mt-1">Results</h2>
      <div className="caseStudies__stripes">
        <img src={stripe_1} />
        <img src={stripe_2} />
        <img src={stripe_3} />
      </div>
    </Section>

    <Section sectionClass="gray">

      <div class="w-wrap">

        <div className="w2-3">
          <h2 className="mt-1">
            New workflows
            <span className="highlighted"> simplify</span> acceptance
            and
            <span className="highlighted"> speed up</span> reviews
          </h2>
          <p className="justified">
            Resistant AI was integrated as part of the application process and workflow triggers were tied
            to the various verdicts provided to each document.
            Applications with “Warning” or “High Risk” documents triggered escalations to the Financial
            Fraud Investigation team.
          </p>
          <p className="justified">
            If the forensic analysis demonstrated clear attempts at fraudulent manipulation, the
            application could be declined immediately. The team would then be freed up to log the
            results and analyze them for fraud trends to build their risk intelligence.
            In less clear cut cases where deeper investigation was needed, time spent on each case was
            cut by 52 minutes.
          </p>
          <p className="justified">
            Meanwhile, confidence in Resistant AI’s verdicts of authenticity meant applications with
            documents that met that standard could automatically move on to underwriting stages. In
            these cases, document assessment was reduced to mere seconds.
          </p>
          <p className="justified">
            Since it only takes 50-80 authentic samples to create new “Trusted” models of authenticity,
            coverage of the document types Habito dealt with increased daily in production from the
            incoming stream of documents.
          </p>
        </div>

        <div className="w1-3">

          <div className="caseTestimonial">
            <h3 class="caseTestimonial__text">
            Resistant AI identifies manipulated documentation far quicker and far more
            accurately than we humans can. It also brings
            us to conclusions faster and with more confidence.
            </h3>
            <div className="caseTestimonial__bottom">
              <p><strong>Matthew Willis</strong></p>
              <p>Habito Financial Crime Investigator</p>
            </div>
          </div>

          <div className="caseTestimonial noMarks">
            <h3 class="caseTestimonial__text">
            Authentic documents were
            identified and moved to
            underwriting automatically.
            </h3>
          </div>

        </div>
      </div>

    </Section>

    </Layout>
  )
}

export default Habito
